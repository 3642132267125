import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') || 'zh', // 语言标识,第一次登录默认是中文
    globalInjection: true,
    messages: {
        zh: require('./local_zh'), // 中文
        en: require('./local_en'), // 英语
    }
})

export default i18n