module.exports =  {
    sysInfo:{
        companyName:"常宝股份",
        plat:"客户服务平台",
        title:"常宝客户服务平台",
    },
    common:{
        no:"不",
        yes:"是",
        cancel:"取消",
        confirm:"确认",
        ok:"确定",
    },
    table:{
        emptyText:"暂无数据",
    },
    login: {
        idTip:"请输入用户ID",
        pswTip:"请输入密码",
        saveId:"记住用户",
        savePsw:"记住密码",
        btnLogin:"登录",
    },
    header:{
        exitText:"您确定要退出登录吗？",
        yes:"确定",
        no:"取消",
        warning:"提示",
        exitSuccess:"退出登录成功。",
        exitCancel:"已取消退出登录。",
    },
    welcome:{
        img1:"../assets/welcome/scorll-01.jpg",
        img2:"../assets/welcome/scorll-02.jpg",
        img3:"../assets/welcome/scorll-03.jpg",
    },
    home:{
        title:"个人中心",
        welcome:"欢迎！",
        name:"姓名",
        phone:"电话",
        address:"地址",
        company:"公司",
        changePwd:"修改密码",
        oldPwd:"原密码",
        newPwd:"新密码",
        cfmPwd:"确认密码",
    },
    QM01:{
        customerName:"客户名称",
        generateDateFr:"生成日期起",
        generateDateTo:"生成日期止",
        itemNo:"序号",
        id:"质保书号",
        orderNo:"内部合同号",
        customOrderNo:"订单号",
        companyCode:"客户名称",
        deliveryDate:"出货日期",
        generateDate:"生成日期",
        weightNo:"码单号",
        heatNo:"炉号",
        truckNo:"车号",
        qrcodeText:"二维码文本",
        dwloadTimes:"下载次数",
        factoryCode:"分厂",
        filePath:"文件路径",
        fileName:"文件(Pdf)",
        spareCitem1:"文件(Excel)",
        recCreator:"记录创建者",
        recCreateTime:"记录创建时间",
        recReviser:"记录修改者",
        recReviseTime:"记录修改时间",
    },
    QM02:{
        customerName:"客户名称",
        generateDateFr:"生成日期起",
        generateDateTo:"生成日期止",
        itemNo:"序号",
        id:"质保书号",
        orderNo:"内部合同号",
        customOrderNo:"订单号",
        companyCode:"客户名称",
        deliveryDate:"出货日期",
        generateDate:"生成日期",
        weightNo:"码单号",
        heatNo:"炉号",
        truckNo:"车号",
        qrcodeText:"二维码文本",
        dwloadTimes:"下载次数",
        mtcQRCode:"质保书二维码",
        close:"关闭",
    }
}