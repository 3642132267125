import request from "@/router/axios";

//初始化
export const init = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
  params.clientInfo = {
    userId:userInfo.id,
    userName:userInfo.name,
    sysTime:(new Date()).toLocaleString(),
    formName:"SI00"
  };
  return request({
    url: "/api/SI00.init",
    method: "post",
    data: params
  });
};