import { createRouter, createWebHistory } from 'vue-router'
import Login from '../components/Login.vue'
import Home from "@/components/Home.vue";
import Welcome from "@/components/Welcome.vue";
import Main from "@/components/Main.vue";
import Index from "@/components/Index.vue";

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/check:id',
    name: 'check:id',
    meta:{
      title:'质保书扫码查询'
    },
    component: ()=>import('@/components/QM/QM03.vue')
  },
  {
    path: '/Index',
    name: 'Index',
    component: Index,
    children:[
      {
        path: '/main',
        name: 'Main',
        component: Main
      },
      {
        path: '/welcome',
        name: 'Welcome',
        meta:{
          title:'欢迎',
          requireAuth:true
        },
        component: Welcome
      },
      {
        path: '/home',
        name: 'Home',
        meta:{
          title:'首页',
          requireAuth:true
        },
        component: Home
      },
      {
        path: '/QM/QM01',
        name: 'VQM01',
        meta:{
          title:'质保书管理',
          requireAuth:true
        },
        component: ()=>import('@/components/QM/QM01.vue')
      },
      {
        path: '/QM/QM02',
        name: 'QM02',
        meta:{
          title:'质保书查询',
          requireAuth:true
        },
        component: ()=>import('@/components/QM/QM02.vue')
      },
      {
        path: '/QM/QM03',
        name: 'QM03',
        meta:{
          title:'质保书扫码查询',
          requireAuth:true
        },
        component: ()=>import('@/components/QM/QM03.vue')
      },
      {
        path: '/SI/SI01',
        name: 'SI01',
        meta:{
          title:'用户管理',
          requireAuth:true
        },
        component: ()=>import('@/components/SI/SI01.vue')
      },
      {
        path: '/SI/SI05',
        name: 'SI05',
        meta:{
          title:'权限管理(个人)',
          requireAuth:true
        },
        component: ()=>import('@/components/SI/SI05.vue')
      },
      {
        path: '/SI/SI06',
        name: 'SI06',
        meta:{
          title:'权限管理(群组)',
          requireAuth:true
        },
        component: ()=>import('@/components/SI/SI06.vue')
      },
      {
        path: '/SI/SIC1',
        name: 'SIC1',
        meta:{
          title:'代码管理',
          requireAuth:true
        },
        component: ()=>import('@/components/SI/SIC1.vue')
      },
      {
        path: '/contactus',
        name: 'Contactus',
        meta:{
          title:'权限管理',
          requireAuth:true,
          url:'http://www.cbsteeltube.com/contactus.html',
          urlEn:'http://en.cbsteeltube.com/contactus.html'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
