<template>
  <div>
    <el-row>
      <el-col :span="4">
        <div style="padding-top: 2px;">
          <img alt="常宝股份" src="../assets/changbao.png" height="60" style="padding-top: 5px;">
        </div>
      </el-col>
      <el-col :span="20">
        <div style="float: right; min-width: 500px;">
          <el-menu  class="el-menu-demo" style="height: 74px;background-color: rgb(2, 112, 193);" active-text-color="white" mode="horizontal" :default-active="this.$route.path">
            <MenuTree :menuData="menuData"></MenuTree>
          </el-menu>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {menu} from "@/api/SI/SI01";
import MenuTree from '@/components/MenuTree.vue'
export default {
  name: "v-header",
  components: {
    MenuTree,
  },
  data(){
    return{
      user:JSON.parse(sessionStorage.getItem("CurrentUser")),
      menuData:[],
    }
  },
  methods: {
    menu(){
      let params = {
        msg:"获取用户菜单",
        data:[]
      };
      menu(params).then(res=>res.data).then(res=>{
        console.log(res);
        if(res.status === 0){
          this.menuData = res.data;
          console.log("菜单列表：", this.menuData);
        } else {
          console.log(res);
          this.$message({message: '菜单获取失败！' + res.msg,type: 'error',});
        }
      })
    },
    toUser() {
      this.$router.push('/home');
    },
    logout() {
      this.$confirm('您确定要退出登录吗？','提示',{
        confirmButtonText:"确定",
        cancelButtonText:"取消",
        type:"warning",
        center:true
      })
        .then(()=>{
          this.$message({
            type:"success",
            message:"退出登录成功。"
          });
          sessionStorage.clear();
          this.$router.push('/');
        })
        .catch(()=>{
          this.$message({
            type:"info",
            message:"已取消退出登录。"
          });
        })
    }
  },
  created() {
    //this.$router.push('/home');
  }
  ,
  mounted(){
      this.menu();
  }
}
</script>

<style>
.flex-grow {
  flex-grow: 1;
}
</style>