<template>
    <div id="imgBox" class="imgBox">
        <img id="scorll_01" class="img-slide img1" src="../assets/welcome/scorll-01.jpg" alt="1">
        <img id="scorll_02" class="img-slide img2" src="../assets/welcome/scorll-02.jpg" alt="2">
        <img id="scorll_03" class="img-slide img3" src="../assets/welcome/scorll-03.jpg" alt="3">
    </div>
    <el-row v-if="lang=='zh'">
        <el-col :span="9">
            <div class="about">
                <h2>关于我们</h2>
                <p style="text-align: left;">江苏常宝钢管股份有限公司是一家专业从事各种专用无缝钢管的制造服务商，是石油油井管和小口径合金高压锅炉管的重点制造企业，主要产品为油气开采用管、电站锅炉用管、工程机械用管以及其他细分市场特殊用管，产品广泛出口欧美、中东、独联体、东南亚和非洲等多个国家和地区，是中国石油销售总公司“能源一号网”会员单位和锅炉业三大龙头企业的 A 级供应商。</p>
                <p style="text-align: center;"><span><a href="http://www.cbsteeltube.com/aboutus.html" target="blank">更多+</a></span></p>
            </div>
        </el-col>
        <el-col :span="5">
            <h2>产品中心</h2>
            <a href="http://www.cbsteeltube.com/product_category/syxyzygg.html" target="blank">石油行业专用钢管</a>
            <br>
            <a href="http://www.cbsteeltube.com/product_category/glxyzygg.html" target="blank">锅炉行业专用钢管</a>
            <br>
            <a href="http://www.cbsteeltube.com/product_category/5296.html" target="blank">汽车行业专用钢管</a>
            <br>
            <a href="http://www.cbsteeltube.com/product_category/5297.html" target="blank">石化行业专用钢管</a>
            <br>
            <a href="http://www.cbsteeltube.com/product_category/gcjxzygg.html" target="blank">工程机械专用钢管</a>
            <br>
            <a href="http://www.cbsteeltube.com/product_category/qttzzygg.html" target="blank">其他特种专用钢管</a>
            <p style="text-align: center;"><span><a href="http://www.cbsteeltube.com/product.html" target="blank">更多+</a></span></p>
        </el-col>
        <el-col :span="5">
            <h2>投资者关系</h2>
            <img src="../assets/welcome/gp.jpg" height="65">
            <br>
            2010年9月21日<br>在深圳中小板发行上市<br>股票代码002478
            <p style="text-align: center;"><span><a href="http://www.cbsteeltube.com/othercate/othername/gszl.html" target="blank">更多+</a></span></p>
        </el-col>
        <el-col :span="5">
            <h2>联系我们</h2>
            <ul style="list-style-type: none;">
                <li class="contactAddress"><p>江苏省常州市延陵东路558号</p></li>
                <li class="contactTel"><p>86-519-88813911</p></li>
                <li class="contactFax"><p>86-519-88812052</p></li>
                <li class="contactEmail">
                    <a href="mailto:cb@cbsteeltube.com" onclick="turn_sta(3,5)" style="color:black"><p>cb@cbsteeltube.com</p></a>
                </li>
            </ul>
            <p style="text-align: center;"><span><a href="http://www.cbsteeltube.com/contactus.html" target="blank">更多+</a></span></p>
        </el-col>
    </el-row>
</template>

<script>
import {changeImg} from "@/api/welcome";
import scorllImg1 from '@/assets/welcome/scorll-01-en.jpg';
import scorllImg2 from '@/assets/welcome/scorll-02-en.jpg';
import scorllImg3 from '@/assets/welcome/scorll-03-en.jpg';

export default {
    name: "v-welcome",
    data() {
        return {
            lang:'zh',
        }
    },
    methods: {

    },
    mounted(){
        this.lang = localStorage.getItem('lang') || 'zh';
        if(this.lang != "zh"){
            var imgBoxDiv = document.getElementById('imgBox');
            imgBoxDiv.style.height = '100%';
            var img1 = document.getElementById('scorll_01');
            var img2 = document.getElementById('scorll_02');
            var img3 = document.getElementById('scorll_03');
            img1.src = scorllImg1;
            img2.src = scorllImg2;
            img3.src = scorllImg3;
        }

        this.$nextTick(()=>{
        //设置定时器，切换图片
        var imgTimer = {
            disIdx: 0
        }
        setInterval(changeImg, 3000, imgTimer);
    })
  }
}
</script>

<style scoped>

.imgBox{
    width: 100%;
    height: 70%;
    margin: 0 auto;
}

.imgBox img{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.img1{
    display: block;
}

.img2{
    display: none;
}

.img3{
    display: none;
}
.imgLink{
    height: 100px;
    width: 100px;
}
.contactAddress{
    padding-left: 25px;
    text-align: left;
    background-repeat:no-repeat;
    background-image:url(../assets/welcome/add.png);
}
.contactTel{
    padding-left: 25px;
    text-align: left;
    background-repeat:no-repeat;
    background-image:url(../assets/welcome/tel.png);
}
.contactFax{
    padding-left: 25px;
    text-align: left;
    background-repeat:no-repeat;
    background-image:url(../assets/welcome/fax.png);
}
.contactEmail{
    padding-left: 25px;
    text-align: left;
    background-repeat:no-repeat;
    background-image:url(../assets/welcome/email.png);
}
</style>