import Vuex from 'vuex'

export default new Vuex.Store({
  state: {
    menu:''
  },
  getters: {
    getActiveMenu (state) {
      return state.menu;
    }
  },
  mutations: {
    setActiveMenu(state, menuList) {
        state.menu = menuList;
    }
  }
})