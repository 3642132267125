import request from "@/router/axios";

//登录
export const login = (params) => {
  // const interfaces = require.resolve("os-browserify/browser").networkInterfaces();
  // for (var devName in interfaces) {
  //     var iface = interfaces[devName];
  //     for (var i = 0; i < iface.length; i++) {
  //         var alias = iface[i];
  //         console.log(alias);
  //         if (alias.family === 'IPv4' && alias.address !== '127.0.0.1' && !alias.internal) {
  //             console.log(alias.address);
  //         }
  //     }
  // }
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
  params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
  console.log(userInfo);
  console.log(params);
  return request({
    url: "/api/login",
    method: "post",
    data: params
  });
};
//查询
export const query = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
    params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
    console.log(userInfo);
    console.log(params);
    return request({
      url: "/api/SI01.query",
      method: "post",
      data: params
    });
  };
//ID号唯一检查
export const uniqueId = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
    params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
    console.log(userInfo);
    console.log(params);
    return request({
      url: "/api/SI01.uniqueId",
      method: "post",
      data: params
    });
  };
//新增
export const add = (params) => {
    let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
    params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
    console.log(userInfo);
    console.log(params);
    return request({
      url: "/api/SI01.add",
      method: "post",
      data: params
    });
  };
//修改
  export const upd = (params) => {
    let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
    params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
    console.log(userInfo);
    console.log(params);
    return request({
      url: "/api/SI01.update",
      method: "post",
      data: params
    });
  };
//初始化密码
export const rePwd = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
  params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
  return request({
    url: "/api/SI01.rePwd",
    method: "post",
    data: params
  });
};
//x修改密码
export const updPwd = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
  params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
  return request({
    url: "/api/SI01.updPwd",
    method: "post",
    data: params
  });
};
//删除
export const del = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
  params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
  return request({
    url: "/api/SI01.del",
    method: "post",
    data: params
  });
};
//查询菜单
export const menu = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
    params.clientInfo = {userId:userInfo.id,userName:userInfo.name,sysTime:(new Date()).toLocaleString()};
    return request({
      url: "/api/SI01.getMenu",
      method: "post",
      data: params
    });
  };
//初始化
export const init = (params) => {
  let userInfo = JSON.parse(sessionStorage.getItem("CurrentUser"));
  params.clientInfo = {
    userId:userInfo.id,
    userName:userInfo.name,
    sysTime:(new Date()).toLocaleString(),
    formName:"SI01"
  };
  // console.log(userInfo);
  // console.log(params);
  return request({
    url: "/api/SI01.init",
    method: "post",
    data: params
  });
};