export const changeImg = (params) => {
        params.disIdx++;
        var a=document.getElementsByClassName("img-slide");
        if(a.length > 0){
            if(params.disIdx >= a.length){
                params.disIdx = 0;
            }
            for(var i=0;i<a.length;i++){
                a[i].style.display='none';
            }
            a[params.disIdx].style.display='block';
        }
  };