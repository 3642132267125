<template>
  <div id="loginContainer">
    <div id="loginBg">
      <el-row style="padding-top: 0px;margin: 0px;" justify="end">
        <div class="langeChg" >
            <el-select v-model="lang" class="selectDeep" size="small" suffix-icon="" @change="langChange(lang)">
              <el-option
                v-for="item in langOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </div>
        <!-- <el-button type="primary" @click="langChange('zh')">中文</el-button>
        <el-button type="primary" @click="langChange('en')">English</el-button> -->
      </el-row>
      <el-row style="padding-top: 10px;" justify="center">
        <img alt="常宝股份" src="../assets/changbao.png" height="80"> 
      </el-row>
      <el-row justify="center" style="height: 20px;">
        <!-- 客户服务平台 -->
        <span style="color: white;padding-left:90px;" :style="{'font-size': platFontSize}">{{$t('sysInfo.plat')}}</span>
      </el-row>
      <el-row style="padding-top: 50px;" justify="center">
          <div style="display: flex;border-bottom:1px solid white; width:75%">
            <el-icon color="white" size="20" style="padding-top: 5px;"><User /></el-icon>
            <el-input :placeholder="$t('login.idTip')" clearable class="inputDeep" v-model="name"></el-input>
          </div>
      </el-row>
      <el-row id="inpPwdRow" style="padding-top: 30px;" justify="center">
        <div style="display: flex;border-bottom:1px solid white; width:75%">
          <el-icon color="white" size="20" style="padding-top: 5px;"><Unlock /></el-icon>
          <el-input type="password" :placeholder="$t('login.pswTip')" clearable class="inputDeep" v-model="password" show-password @keyup.enter="login"></el-input>
        </div>
      </el-row>
      <el-row id="savInfRow" style="padding-top: 30px;" justify="center">
        <div style="width:75%;text-align: right;">
          <el-checkbox :label="$t('login.saveId')" size="small" style="color: white;" v-model="nmeFlag"/>
          <el-checkbox :label="$t('login.savePsw')" size="small" style="color: white;" v-model="pswFlag"/>
        </div>
      </el-row>
      <el-row style="padding-top: 20px;" justify="center">
        <div style="display: flex;width:75%">
          <el-button style="width: 100%;" color="#00264d" type="primary" @click="login">{{$t('login.btnLogin')}}</el-button>
        </div>
      </el-row>
      
    </div>
  </div>
</template>

<script>

import {login} from "@/api/login";
export default {
  name: "v-login",
  data(){
    return {
      name : '',
      password : '',
      nmeFlag:false,
      pswFlag:false,
      platFontSize:'20px',
      lang:'zh',
      langOptions:[{
          value: 'zh',
          label: '中文'
        }, {
          value: 'en',
          label: 'English'
        }]
    };
  },
  methods: {
    clear() {
      this.name = '';
      this.password = '';
    },
    login() {
      if(this.name.trim().length == 0){
        this.$message({message: this.$t('login.idTip'), type: 'error',});
        return;
      }
      if(this.password.trim().length == 0){
        this.$message({message: this.$t('login.pswTip'), type: 'error',});
        return;
      }
      let params = {
        msg:"用户登录",
        data:[{name:this.name, password:this.password}]
      };

      login(params).then(res=>res.data).then(res=> {
        console.log("---", res, "---");
        //this.$router.push('/home');
        if(0 == res.status){
          //存储
          sessionStorage.setItem("CurrentUser", JSON.stringify(res.data[0]))
          
          var localUserInfo={userName:'',userPassword:'',nmeFlag:false,pswFlag:false}
          if(this.nmeFlag){
            localUserInfo.userName = window.btoa(this.name);
            localUserInfo.nmeFlag = this.nmeFlag;
          }
          if(this.pswFlag){
            localUserInfo.userPassword = window.btoa(this.password);
            localUserInfo.pswFlag = this.pswFlag;
          }
          localStorage.setItem('localUserInfo',JSON.stringify(localUserInfo)); 

          //跳转到主页
          this.$router.push('/welcome');
        }
        else {
          alert(res.msg);
        }
      });
    },
    langChange(value){
        if(value=='zh'){
          this.$i18n.locale = "zh";
          localStorage.setItem('lang',"zh");
          this.platFontSize = "28px";
        }else{
          this.$i18n.locale = "en";
          localStorage.setItem('lang',"en");
          this.platFontSize = "20px";
        }
        document.title = this.$t('sysInfo.title');
    },
  }
  ,
  mounted(){
    loginWinSize();
    window.onresize = loginWinSize;
    var localUserInfo = JSON.parse(localStorage.getItem('localUserInfo'));
    if(null != localUserInfo)
    {
      if(localUserInfo.nmeFlag){
        this.nmeFlag = localUserInfo.nmeFlag;
        this.name = window.atob(localUserInfo.userName);
      }
      if(localUserInfo.pswFlag){
        this.pswFlag = localUserInfo.pswFlag;
        this.password = window.atob(localUserInfo.userPassword);
      }
    }
    
    var locLang = localStorage.getItem('lang');
    console.log("---locLang:", locLang, "---");
    if(null == locLang){
      var navLang = navigator.language;
      console.log("---navLang:", navLang, "---");
      if(navLang != 'zh-Ch')
      {
        this.lang = 'en';
        localStorage.setItem('lang',this.lang);
      }
    } else {
      this.lang = locLang;
    }
    if(this.lang != 'zh')
    {
      document.title = this.$t('sysInfo.title');
    }
  }
};

var loginWinSize = function(){
  var lbg = document.getElementById("loginBg");
  var sir = document.getElementById("savInfRow");
  var ipr = document.getElementById("inpPwdRow");
  if(lbg !== null && sir !== null && ipr !== null){
    if(screen.height <= 720){
      lbg.style.width = '400px';
      lbg.style.height = '450px';
      sir.style.paddingTop = '50px';
      sir.style.marginTop = '0px';
      ipr.style.marginTop = '0px';
      sir.style.paddingBottom = '0px';
    } else {
      lbg.style.width = '550px';
      lbg.style.height = '650px';
      sir.style.paddingBottom = '50px';
      sir.style.marginTop = '50px';
      ipr.style.marginTop = '10px';
    }
  }
}
</script>
<style scoped>
  #loginContainer {
    background-image: url("../assets/indexBg.jpg");
    background-repeat:no-repeat;
    background-size:100% 100%;
    background-attachment: fixed;
    height: 100%;
    min-width: 500px;
    min-height: 550px;
  }
.inputDeep>>>.el-input__wrapper {
  border: none !important;
  box-shadow: none !important;
  padding: 2 0 0 0px;
  background: transparent;
  }
.inputDeep>>>.el-input__inner {
  color: white !important;
}
*{
    margin: 0px;
    padding: 0px;
}
#loginBg{
    width: 550px;
    height: 650px;
    max-width: 550px;
    max-height: 650px;
    position: fixed;
    background: rgba(0, 50, 102,0.5);
    padding-top: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}
.langeChg { width:50px;height:20px;color: white;margin:10px;text-align:center}

.selectDeep>>>.el-input__wrapper {
  border: none !important;
  box-shadow: none !important;
  padding: 0 0 0 0px;
  background: transparent;
  }
.selectDeep>>>.el-input__inner {
  color: white !important;
  border: 2 2 2 2px solid white;
  text-align: center;
}

</style>