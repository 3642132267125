module.exports =  {
    sysInfo:{
        companyName:"Chang Bao",
        plat:"Customer Service Platform",
        title:"Jiangsu Chang bao Customer Service Platform",
    },
    common:{
        no:"No",
        yes:"Yes",
        cancel:"Cancel",
        confirm:"Confirm",
        ok:"Ok",
    },
    table:{
        emptyText:"No data found",
    },
    login: {
        idTip:"Please enter user ID",
        pswTip:"Please enter password",
        saveId:"Save user ID",
        savePsw:"Save password",
        btnLogin:"log on",
    },
    header:{
        exitText:"Are you sure to log out?",
        yes:"Yes",
        no:"No",
        warning:"Warning",
        exitSuccess:"Success",
        exitCancel:"Cancel",
    },
    welcome:{
        img1:"../assets/welcome/scorll-01-en.jpg",
        img2:"../assets/welcome/scorll-02-en.jpg",
        img3:"../assets/welcome/scorll-03-en.jpg",
    },
    home:{
        title:"Personal settings",
        welcome:"Welcome ",
        name:"Name",
        phone:"Phone",
        address:"Address",
        company:"Company",
        changePwd:"Change password",
        oldPwd:"Original password",
        newPwd:"New password",
        cfmPwd:"Confirm Password",
    },
    QM01:{
        customerName:"Customer Name",
        generateDateFr:"Date of Issue From",
        generateDateTo:"Date of Issue To",
        itemNo:"Item No.",
        id:"Certificate NO.",
        orderNo:"Order NO.",
        customOrderNo:"Contract NO.",
        companyCode:"Customer Name",
        deliveryDate:"Date of Shipment",
        generateDate:"Date of Issue",
        weightNo:"Weight NO.",
        heatNo:"Heat NO.",
        truckNo:"Wagon NO.",
        qrcodeText:"QR Code Content",
        dwloadTimes:"Download Count",
        factoryCode:"Production mill ",
        filePath:"File Path",
        fileName:"PDF Format",
        spareCitem1:"EXCEL Format",
        recCreator:"Record Creator",
        recCreateTime:"Record Creation Date",
        recReviser:"Record modifier",
        recReviseTime:"Record Modification Date",
    },
    QM02:{
        customerName:"Customer Name",
        generateDateFr:"Date of Issue From",
        generateDateTo:"Date of Issue To",
        itemNo:"Item No.",
        id:"Certificate NO.",
        orderNo:"Order NO.",
        customOrderNo:"Contract NO.",
        companyCode:"Customer Name",
        deliveryDate:"Date of Shipment",
        generateDate:"Date of Issue",
        weightNo:"Weight NO.",
        heatNo:"Heat NO.",
        truckNo:"Wagon NO.",
        qrcodeText:"QR Code Content",
        dwloadTimes:"Download Count",
        mtcQRCode:"QR Code",
        close:"Close",
    }
}