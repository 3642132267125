<template>
  <div style="height: 100%;">
    <el-header style="background-color: rgb(2, 112, 193);height: 75px;border-bottom:2px solid white;">
      <Header/>
    </el-header>
    <el-main style="border-top:0px;background-color:rgb(179, 216, 255);height: 92%;padding: 2px 0px 0px 0px;">
      <Main></Main>
    </el-main>
  </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Main from "@/components/Main.vue";

export default {
  name: "v-index",
  components: {
    Header,
    Main
  }
}
</script>

<style scoped>
.el-main {
  padding:5px;
}
</style>