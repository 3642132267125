<template>
  <h2>{{$t('home.welcome') + user.name}}</h2>
  <el-row>
    <el-col :span="12" :offset="6">
      <el-descriptions :title="$t('home.title')" :column="3" border>
        <el-descriptions-item label="ID" label-align="right" align="center">
          {{user.id}}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('home.phone')" label-align="right" align="center">
          {{user.phone}}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('home.company')" label-align="right" align="center">
          {{user.companyCname}}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('home.name')" label-align="right" align="center">
          {{user.name}}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('home.address')" label-align="right" align="center">
          {{user.address}}
        </el-descriptions-item>
      </el-descriptions>
      <div style="text-align: right; padding-top: 5px">
        <el-button type="primary" @click="updPwd" v-if="btnInfo.includes('updPwd')">{{$t('home.changePwd')}}</el-button>
      </div>
    </el-col>
  </el-row>
  
  <el-dialog v-model="dialogVisible" :title="$t('home.changePwd')" width="30%" center>
    <el-form label-width="130px">
      <el-form-item label="ID">
        <el-input v-model="user.id" disabled/>
      </el-form-item>
      <el-form-item :label="$t('home.name')">
        <el-input v-model="user.name" disabled/>
      </el-form-item>
      <el-form-item :label="$t('home.oldPwd')">
        <el-input type="password" v-model="oldPwd" show-password/>
      </el-form-item>
      <el-form-item :label="$t('home.newPwd')">
        <el-input type="password" v-model="newPwd" show-password/>
      </el-form-item>
      <el-form-item :label="$t('home.cfmPwd')">
        <el-input type="password" v-model="cfmPwd" show-password/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="pwdCfm">{{$t('common.confirm')}}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {updPwd} from "@/api/SI/SI01";
import {init} from "@/api/SI/SI00";
export default {
  name: "v-home",
  data(){
    return{
      btnInfo:[],
      dialogVisible:false,
      newPwd:"",
      oldPwd:"",
      cfmPwd:"",
      user:JSON.parse(sessionStorage.getItem("CurrentUser"))
    }
  },
  methods: {
    init(){
      let params = {
        msg:"初始化",
        data:[]
      };
      init(params).then(res=>res.data).then(res=>{
        if(res.status === 0){
          console.log(res);
          for(var i=0; i<res.data.length; i++){
            this.btnInfo.push(res.data[i].buttonName);
          }
        } else {
          console.log(res);
          this.$message({message: '界面初始化失败！' + res.msg,type: 'error',});
        }
      })
    },
    pwdCfm(){
      if(this.oldPwd.trim().length == 0){
        this.$message({message: '请输入原密码！', type: 'error',});
        return;
      }
      if(this.newPwd.trim().length == 0){
        this.$message({message: '请输入新密码！', type: 'error',});
        return;
      }
      if(this.newPwd != this.cfmPwd){
        this.$message({message: '密码二次输入有误！', type: 'error',});
        return;
      }
      let params = {
        msg:"修改密码",
        data:[{id:this.user.id, password:this.newPwd, oldPassword:this.oldPwd}]
      };
      updPwd(params).then(res=>res.data).then(res=>{
        if(res.status === 0){
          this.$message({message: '修改密码成功。',type: 'success',});
          this.dialogVisible = false;
          this.oldPwd = '';
          this.newPwd = '';
          this.cfmPwd = '';
        } else {
          this.$message({message: res.msg,type: 'error',});
        }
      });
    },
    updPwd(){
      console.log('修改密码');
      this.dialogVisible = true;
    }
  },
  mounted(){
      this.init();
  }
}
</script>

<style scoped>
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
}
</style>