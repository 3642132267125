import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIconList from '@element-plus/icons-vue'
import axios from "axios"
import Utils from './common/utils.js'
import './assets/css/common.css'
import i18n from './language'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App);
for(const name in ElIconList){
    app.component(name, ElIconList[name]);
}

router.beforeEach((to, from, next) => {
    if(to.meta.url && to.meta.url.length > 0){
        var a = document.createElement('a');
        var lang = localStorage.getItem('lang') || 'zh';
        if("zh" != lang){
            a.href = to.meta.urlEn;
        } else {
            a.href = to.meta.url;
        }
        
        a.target = "_blank_";
        a.click();
        return;
    }
    if(to.meta.requireAuth){
        let useInfo = JSON.parse(sessionStorage.getItem("CurrentUser"))
        if(!useInfo){
            next({
                path: '/'//无登录状态,先跳转到登录页面
                //query: { redirected: to.path } //登录成功后跳转回到该路由
            })
        }
    }
    /* 路由发生变化修改页面title */
    // if (to.meta.title) {
    //     document.title = to.meta.title + '[' + to.name + ']'
    // }
    next();
})

app.use(i18n);
app.use(ElementPlus,{locale: zhCn}).use(router).use(store).mount('#app');
app.config.globalProperties.$utils=Utils;
app.config.globalProperties.$axios = axios;
//app.config.globalProperties.$httpUrl = 'http://localhost:8081';
app.config.globalProperties.$httpUrl = 'https://icloud2c.cbsteeltube.com/api'