<template>
    <template v-for="(item) in menuData" :key="item.id">
        <el-sub-menu class="menuDeep" v-if="item.children&&item.children.length>0" :index="item.id + ''" style="font-size: 20px;color: white;background-color: rgb(2, 112, 193);">
            <template #title >
                <span style="font-size: 20px;color: white;">{{lang==='zh' ? item.label : item.labelEn}}</span>
            </template>
            <MenuTree :menuData="item.children"></MenuTree> 
        </el-sub-menu>
        <el-menu-item v-else @click="clickMenu" :index="'/'+item.router" style="font-size: 20px;color: white;background-color: rgb(2, 112, 193);">
            <template #title>
                {{lang==='zh' ? item.label : item.labelEn}}
            </template>
        </el-menu-item>
    </template>
</template>

<script>
  export default {
    name: 'MenuTree',
    props: ['menuData'],
    data() {
        return {
        lang:'zh',
        }
    },
    methods: {
        clickMenu(item){
            console.log("item=", item);
            if(item.index == "/Exit"){
                this.$confirm(this.$t('header.exitText'),this.$t('header.warning'),{//您确定要退出登录吗？//提示
                    confirmButtonText:this.$t('header.yes'),//确定
                    cancelButtonText:this.$t('header.no'),//取消
                    type:"warning",
                    center:true
                })
                .then(()=>{
                this.$message({
                    type:"success",
                    message:this.$t('header.exitSuccess')//退出登录成功。
                });
                sessionStorage.clear();
                this.$router.push('/');
                })
                .catch(()=>{
                this.$message({
                    type:"info",
                    message:this.$t('header.exitCancel')//已取消退出登录。
                });
                });
            } else {
                this.$router.push(item.index);
            }
        }
    },
    mounted(){
      this.lang = localStorage.getItem('lang') || 'zh';
    }
  }
</script>

<style scoped>
  #loginContainer {
    background-image: url("../assets/indexBg.jpg");
    background-repeat:no-repeat;
    background-size:100% 100%;
    background-attachment: fixed;
    height: 100%;
  }

.menuDeep>>>.el-sub-menu__title.el-tooltip__trigger.el-tooltip__trigger:hover {
  background-color:rgb(107, 145, 212);
}
</style>