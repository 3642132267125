<template>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
</template>

<script>
export default {
  name: "VMAIN",
  computed: {
  key() {
    // return this.$key();
    return this.$route.path
  }
}
}
</script>

<style scoped>

</style>